export default async function fetchService(url, method, reqJson) {
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  if (reqJson !== undefined) {
    options.body = JSON.stringify(reqJson);
  }

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    // console.error("There was a problem with the fetch operation: ", error);
    return null;
  }
}
