import { Link } from "react-router-dom";

export default function ComingSoon({ setPage }) {
  return (
    <>
      <div className="flex max-h-screen min-h-screen flex-col items-center justify-center gap-10 bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
        <div className="scale-150 font-caviar-bold text-4xl text-white duration-500 hover:text-red-300 hover:duration-500">
          Coming Soon
        </div>
        <div className="mx-5 px-5 font-caviar text-base text-white duration-500 hover:text-red-300 hover:duration-500">
          Kami sedang mempersiapkan sesuatu yang luar biasa untuk komunitas
          kita!
        </div>
        <div className="mx-5 px-5 font-caviar text-base text-white duration-500 hover:text-red-300 hover:duration-500">
          Halaman ini akan segera hadir dengan berbagai fitur menarik yang
          dirancang khusus untuk memenuhi kebutuhan dan keinginan kalian.
        </div>
        <div className="mx-5 px-5 font-caviar text-base text-white duration-500 hover:text-red-300 hover:duration-500">
          Sementara itu, jangan ragu untuk menjelajahi bagian lain dari website
          kami dan ikuti group WhatsApp untuk berita terbaru.
        </div>
        <div className="mx-5 px-5 font-caviar text-base text-white duration-500 hover:text-red-300 hover:duration-500">
          Terima kasih atas kesabaran dan dukungannya! Komunitas Home Credit
          Banjarmasin 1
        </div>
        <div className="mx-5 px-5 font-caviar text-base text-white duration-500 hover:text-red-300 hover:duration-500">
          By Apriadi Salim & DM Resza Ganda Putra
        </div>
        <Link
          className="text-1xl scale-150 font-caviar text-blue-300 duration-500"
          onClick={() => setPage("home")}
        >
          Back To Home
        </Link>
      </div>
    </>
  );
}
