export default function Popup({
  title,
  message,
  callback,
  button = undefined,
  callbackButton = undefined,
}) {
  const handleClick = () => {
    callback();
  };
  return (
    <>
      <div className="absolute z-10 flex max-h-screen min-h-screen min-w-full flex-col items-center justify-center bg-transparent">
        <div className="flex h-auto w-72 flex-col items-center justify-center rounded-3xl bg-gradient-to-b from-[#708F96] to-[#364649] text-center font-caviar">
          <span className="m-5 font-caviar-bold text-2xl">{title}</span>
          <div className="m-5 flex flex-col gap-5">
            <span>{message}</span>
          </div>
          <div className="m-5 flex gap-10">
            <button
              id="popup-button"
              className="rounded-xl bg-[#708F96] p-2 px-10 text-white"
              onClick={handleClick}
            >
              Oke
            </button>
            {button !== undefined && (
              <button
                id="popup-button"
                className="rounded-xl bg-[#708F96] p-2 px-10 text-white"
                onClick={callbackButton}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
