import { useEffect } from "react";

export default function SearchTextBoxComponent({
  setPage,
  title,
  id_input,
  placeholder_input,
  id_search,
  id_selected_search,
  onChange = undefined,
}) {
  useEffect(() => {
    const selectedSearchElement = document.getElementById(id_selected_search);

    const observer = new MutationObserver(() => {
      if (selectedSearchElement.children.length === 0) {
        selectedSearchElement.classList.remove(
          "overflow-y-scroll",
          "rounded-2xl",
          "border-2",
          "border-[#364649]",
          "bg-[#364649]",
          "duration-500",
        );
      } else {
        selectedSearchElement.classList.add(
          "overflow-y-scroll",
          "rounded-2xl",
          "border-2",
          "border-[#364649]",
          "bg-[#364649]",
          "duration-500",
        );
        // Scroll to the bottom
        selectedSearchElement.scrollTop = selectedSearchElement.scrollHeight;
      }
    });

    observer.observe(selectedSearchElement, { childList: true });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, [id_selected_search]);

  return (
    <div className="m-5 flex min-w-64 flex-col items-center gap-2 rounded-[3rem] bg-[#708F96] p-2 px-5 shadow-2xl">
      <h1 className="mt-2 font-caviar-bold text-3xl">{title}</h1>
      <input
        id={id_input}
        className="m-3 w-64 rounded-lg bg-[#364649] p-2 text-white"
        placeholder={placeholder_input}
        {...(onChange !== undefined ? { onChange: onChange } : {})}
      />
      <div
        id={id_search}
        className="flex max-h-64 flex-col overflow-y-auto rounded-2xl bg-[#364649] duration-500"
      ></div>
      <div
        id={id_selected_search}
        className="flex max-h-64 flex-col gap-2 overflow-y-scroll"
      ></div>
    </div>
  );
}
