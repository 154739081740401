import fetchService from "../fetch.js";
import API from "./main_api.js";

export default class SalesroomRequestAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api/salesroom-request";
  }
  async deleteSalesroomRequest(id) {
    const response = await fetchService(`${this.url}/${id}`, "DELETE");
    return response;
  }
  async getSalesroomRequest() {
    const response = await fetchService(this.url, "GET");
    return response;
  }
  async getSalesroomRequestByName(name) {
    const response = await fetchService(`${this.url}/name/${name}`, "GET");
    return response;
  }
  async getSalesroomRequestByNameLike(name) {
    const response = await fetchService(`${this.url}/name-like/${name}`, "GET");
    return response;
  }
  async getSalesroomRequestByBettwen(from, to) {
    const response = await fetchService(`${this.url}/${from}/${to}`, "GET");
    return response;
  }
  async getSalesroomRequestToday() {
    const response = await fetchService(`${this.url}/today`, "GET");
    return response;
  }
  async addSalesroomRequest(
    nik_sa,
    name,
    sr_open_close,
    code_open_close,
    open_or_close,
  ) {
    const _nik_sa = nik_sa !== undefined;
    const _name = name !== undefined;
    const _sr_open_close = sr_open_close !== undefined;
    const _code_open_close = code_open_close !== undefined;
    const _open_or_close = open_or_close !== undefined;

    if (
      !_nik_sa &&
      !_name &&
      !_sr_open_close &&
      !_code_open_close &&
      !_open_or_close
    ) {
      return null;
    }

    const response = await fetchService(this.url, "POST", {
      nik_sa: nik_sa,
      name: name,
      sr_open_close: sr_open_close,
      code_open_close: code_open_close,
      open_or_close: open_or_close,
    });
    return response;
  }
  async addSalesroomRequestBulk(
    nik_sa,
    name,
    sr_open_close,
    code_open_close,
    open_or_close,
  ) {
    const _nik_sa = nik_sa !== undefined;
    const _name = name !== undefined;
    const _sr_open_close = sr_open_close !== undefined;
    const _code_open_close = code_open_close !== undefined;
    const _open_or_close = open_or_close !== undefined;
    if (
      !_nik_sa &&
      !_name &&
      !_sr_open_close &&
      !_code_open_close &&
      !_open_or_close
    ) {
      return null;
    }

    const response = await fetchService(this.url + "/bulk", "POST", {
      nik_sa: nik_sa,
      name: name,
      sr_open_close: sr_open_close,
      code_open_close: code_open_close,
      open_or_close: open_or_close,
    });
    return response;
  }
}
