import { useEffect, useMemo, useState } from "react";
import SalesAPI from "../services/api/sales.js";
import AuthenticatedAPI from "../services/api/authenticated.js";
import AuthenticationAPI from "../services/api/authentication.js";

export default function Home({ setPage }) {
  const [onStart, setOnStart] = useState(false);
  const [dataPermission, setDataPermission] = useState(null);
  const [name, setName] = useState(null);
  const authenticatedAPI = useMemo(() => new AuthenticatedAPI(), []);
  const authenticationAPI = useMemo(() => new AuthenticationAPI(), []);
  const salesAPI = useMemo(() => new SalesAPI(), []);

  const handleLogout = async () => {
    try {
      const response = await authenticationAPI.logout();
      if (response.status === 200) {
        setPage("login");
      }
    } catch (err) {
      setPage("login");
    }
  };

  const handleToRequestSR = () => {
    setPage("request-sr");
  };

  const handleToCheckRequestSR = () => {
    setPage("check-request-sr");
  };

  const handleWelcome = () => {
    return (
      <h1
        id="welcome"
        data-name={name}
        className="m-10 mt-36 flex items-center justify-start text-5xl sm:m-40"
      >
        {`Welcome, ${name}`}
      </h1>
    );
  };

  const createButton = (text, onClick, id = undefined) => {
    if (id === undefined) {
      return (
        <button
          className="m-2 rounded-xl bg-[#708F96] p-2 text-center"
          onClick={onClick}
        >
          {text}
        </button>
      );
    } else {
      return (
        <button
          id={id}
          className="m-2 rounded-xl bg-[#708F96] p-2 text-center"
          onClick={onClick}
        >
          {text}
        </button>
      );
    }
  };

  useEffect(() => {
    const isAuthenticated = async () => {
      try {
        const response0 = await authenticatedAPI.isAuthenticated();
        if (response0 === null) {
          setPage("login");
        }
        if (response0.status !== 200) {
          setPage("login");
        }
        const response1 = await authenticatedAPI.getDataPermission();
        if (response1 === null) {
          setPage("login");
        }
        if (response1.status !== 200) {
          setPage("login");
        }
        const dataResponse1 = await response1.json();
        setDataPermission(dataResponse1.payload);
        if (dataResponse1.payload.permission !== 0) {
          const response2 = await salesAPI.getSalesByUsername(
            dataResponse1.payload.username,
          );
          if (response2 === null) {
            setPage("login");
          }
          if (response2.status !== 200) {
            setPage("login");
          }
          const dataResponse2 = await response2.json();
          setName(dataResponse2[0].name);
        } else {
          setName("District Manager");
        }
      } catch (err) {
        setPage("login");
      }
      setOnStart(true);
    };
    isAuthenticated();
  }, [setPage, authenticatedAPI, salesAPI, setName]);

  if (!onStart) return null;
  return (
    <>
      <button
        id="logout-button"
        className="absolute m-5 rounded-xl bg-[#708F96] p-2 text-end font-caviar shadow-2xl"
        onClick={handleLogout}
      >
        Logout
      </button>

      <div className="max-h-max min-h-screen bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
        <div className="col-span-1 flex min-h-screen flex-col gap-5">
          {handleWelcome()}
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-4">
            <h1 className="font-caviar-bold text-2xl">Sales Room</h1>
            <div className="grid grid-cols-2">
              {createButton("Request SR", handleToRequestSR, "request-sr")}
              {createButton(
                "Check Request SR",
                handleToCheckRequestSR,
                "check-request-sr",
              )}
            </div>
          </div>
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-4">
            <h1 className="font-caviar-bold text-2xl">Simulation</h1>
            <div className="grid grid-cols-2">
              {createButton("Simulasi Kredit", () => setPage("coming-soon"))}
              {createButton("Simulasi Pengajuan", () => setPage("coming-soon"))}
            </div>
          </div>
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-4">
            <h1 className="font-caviar-bold text-2xl">Database</h1>
            <div className="grid grid-cols-2">
              {createButton("Database Komoditas", () => setPage("coming-soon"))}
              {createButton("Database Nasabah", () => setPage("coming-soon"))}
              {createButton("Management Nasabah", () => setPage("coming-soon"))}
            </div>
          </div>
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-4">
            <h1 className="font-caviar-bold text-2xl">Administration</h1>
            <div className="grid grid-cols-2">
              {createButton("Report Punisment", () => setPage("coming-soon"))}
              {createButton("Report Keuangan", () => setPage("coming-soon"))}
            </div>
          </div>

          <button
            className="absolute right-0 m-5 rounded-xl bg-[#708F96] p-2 font-caviar
          shadow-2xl"
            onClick={() => setPage("setting")}
          >
            Setting
          </button>
        </div>
      </div>
    </>
  );
}
