import CryptoJS from "crypto-js";

export function getSHA256(text) {
  return CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
}

export function getAsymmetricKeyPair() {
  return CryptoJS.lib.WordArray.random(32);
}

export function encryptAsymmetric(text, publicKey) {
  return CryptoJS.AES.encrypt(text, publicKey).toString();
}
export function decryptAsymmetric(ciphertext, privateKey) {
  return CryptoJS.AES.decrypt(ciphertext, privateKey).toString(
    CryptoJS.enc.Utf8,
  );
}

export function getBackendURL() {
  // return "http://localhost:3500";
  return "https://server.creditsolution.my.id";
}

export function checkAuth(cookies) {
  let cookie = cookies.token;
  if (!cookie) {
    console.log("Token not found");
  }
  // cek jika token berisi "Bearer "
  if (cookie.includes("Bearer ")) {
    // menghapus "Bearer " dari token
    cookie = cookie.replace("Bearer ", "");
  }
  fetch(`${getBackendURL()}/auth`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      // console.log(err);
      return false;
    });
}
