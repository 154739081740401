import { Link } from "react-router-dom";

export default function NotFound404() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-10 bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
      <div className="scale-150 font-caviar-bold text-6xl duration-500 hover:text-red-300 hover:duration-500">
        404
      </div>
      <div className="scale-150 font-caviar text-2xl duration-500 hover:text-red-300 hover:duration-500">
        Halaman Tidak Ada
      </div>
      <Link
        className="text-1xl scale-150 font-caviar duration-500 hover:text-blue-300 hover:duration-500"
        to="/"
      >
        Back To Home
      </Link>
    </div>
  );
}
