export default function BackToHomeComponent({ setPage }) {
  const handleToHome = () => {
    setPage("home");
  };

  return (
    <button
      className="absolute m-5 rounded-xl border-2 border-black bg-[#708F96] p-2 font-caviar shadow-2xl"
      onClick={handleToHome}
    >
      Home
    </button>
  );
}
