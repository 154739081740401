import { useEffect, useState } from "react";

export default function SearchSADropBoxComponent({
  fetchData = undefined,
  setSelectedSAParent = undefined,
}) {
  const [OnList, setOnList] = useState(false);
  const [listSA, setListSA] = useState([]);
  const [selectedSA, setSelectedSA] = useState("Pilih nama SA");

  useEffect(() => {
    if (fetchData !== undefined) {
      fetchData(setListSA);
    }
  }, [fetchData]);

  return (
    <div className="m-5 flex min-w-64 flex-col items-center gap-2 rounded-[3rem] bg-[#708F96] p-2 px-5 shadow-2xl">
      <h1 className="mt-2 font-caviar-bold text-3xl">Nama Sales</h1>
      <div className="flex flex-col items-center justify-center">
        <div className={`${OnList ? "hidden" : "block"}`}>
          <button
            id="nama-sales-button"
            className="m-3 mb-0 w-64 rounded-lg bg-[#364649] p-2 text-white"
            onClick={() => setOnList(!OnList)}
          >
            <p className="inline">{selectedSA}</p>
            {selectedSA === "Pilih nama SA" && (
              <img
                className="inline h-4 w-4 -translate-y-[0.1rem]"
                src="./img/down-arrow.png"
                alt="arrow-down"
              />
            )}
          </button>
        </div>
        <div className={`${OnList ? "block" : "hidden"}`}>
          <div className="z-10 mt-0 max-h-64 w-64 snap-y overflow-y-scroll rounded-lg bg-[#364649] text-white shadow-2xl">
            <div
              id="list-sales"
              className="z-20 col-span-1 flex flex-col items-center justify-center"
            >
              {listSA.map((nama) => (
                <button
                  className="my-2 snap-start py-2 text-center"
                  key={nama}
                  onClick={(name) => {
                    setSelectedSA(name.target.textContent);
                    setSelectedSAParent(name.target.textContent);
                    setOnList(false);
                  }}
                >
                  {nama}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        id="listSales"
        className="flex max-h-64 flex-col overflow-y-auto rounded-2xl bg-[#364649] duration-500"
      ></div>
      <div
        id="listSelectedSales"
        className="flex max-h-64 flex-col gap-2"
      ></div>
    </div>
  );
}
