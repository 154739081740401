import { useState } from "react";
import Login from "./page/login";
import Home from "./page/home";
import OpenBlockSR from "./page/openblocksr";
import SalesSRCheck from "./model/sales_checksr";
import Setting from "./page/setting";
import ComingSoon from "./page/comingsoon";

export default function App() {
  const [page, setPage] = useState("home");
  switch (page) {
    case "home":
      return <Home setPage={setPage} />;
    case "login":
      return <Login setPage={setPage} />;
    case "request-sr":
      return <OpenBlockSR setPage={setPage} />;
    case "check-request-sr":
      return <SalesSRCheck setPage={setPage} />;
    case "setting":
      return <Setting setPage={setPage} />;
    case "coming-soon":
      return <ComingSoon setPage={setPage} />;
    default:
      return <Home setPage={setPage} />;
  }
}
