import { useState, useEffect, useMemo, useCallback } from "react";
import SalesroomAPI from "../services/api/salesroom.js";
import SalesAPI from "../services/api/sales.js";
import OpenBlock from "../model/openblock.js";
import AuthenticatedAPI from "../services/api/authenticated.js";
import SalesroomRequestAPI from "../services/api/salesroomrequest.js";
import BackToHomeComponent from "../components/openblocksr/backtohome.js";
import SearchTextBoxComponent from "../components/openblocksr/searchtextbox.js";
import SearchSADropBoxComponent from "../components/openblocksr/searchsadropbox.js";
import Popup from "../components/popup.js";

export default function OpenBlockSR({ setPage }) {
  const [onPopup, setOnPopup] = useState(false);
  const [permission, setPermission] = useState(-1);
  const salesroomAPI = useMemo(() => new SalesroomAPI(), []);
  const salesAPI = useMemo(() => new SalesAPI(), []);
  const authenticatedAPI = useMemo(() => new AuthenticatedAPI(), []);
  const salesroomRequestAPI = useMemo(() => new SalesroomRequestAPI(), []);
  const [selectedSA, setSelectedSA] = useState("");
  const [listNamaSR, setListNamaSR] = useState([]);
  const [listSelectedOpenSRState, setListSelectedOpenSRState] = useState([]);
  const [listSelectedCloseSRState, setListSelectedCloseSRState] = useState([]);
  const openCloseModel = new OpenBlock(
    [listSelectedOpenSRState],
    [listSelectedCloseSRState],
  );

  const resetOnPopup = () => {
    setOnPopup(false);
    setPage("home");
  };

  const setSales = async (listSales) => {
    const response = await salesAPI.getSales();
    if (response.status !== 200) {
      console.log("Fetch Sales failed");
      return;
    } else {
      const data = await response.json();
      let listName = [];
      data.map((sales) => {
        listName.push(sales.name);
        return null;
      });
      let sortedName = listName.sort();
      listSales(sortedName);
    }
  };

  const getSalesRoom = useCallback(async () => {
    const response = await salesroomAPI.getSalesroom();
    if (response.status !== 200) {
      console.log("Fetch Salesroom failed");
      return;
    } else {
      const data = await response.json();
      let listName = [];
      data.map((sr) => {
        listName.push([sr.id, sr.name]);
        return null; // Add a return statement here
      });
      setListNamaSR(listName);
    }
  }, [salesroomAPI]);

  const getDataPermission = useCallback(async () => {
    const response = await authenticatedAPI.getDataPermission();
    if (response.status !== 200) {
      console.log("Fetch Permission failed");
      return;
    } else {
      const data = await response.json();
      setPermission(data.payload.permission);
    }
  }, [authenticatedAPI]);

  // Open SR

  const onChangeSearchOpenSR = (e) => {
    // menghapus spasi didepan dan akhir
    const keyword = e.target.value.trim().toLowerCase();
    if (keyword === "") {
      // hapus semua child pada element dengan id listOpenSR
      const listOpenSR = document.getElementById("listOpenSR");
      listOpenSR.innerHTML = "";
    } else {
      openListSearch(keyword);
    }
  };
  const openListSearch = (keyword) => {
    const filterNamaSR = (keyword) => {
      return listNamaSR.filter(([id, nama]) =>
        nama.toLowerCase().includes(keyword),
      );
    };
    const listFiltered = filterNamaSR(keyword);
    // tambah child pada element dengan id listOpenSR
    const listOpenSR = document.getElementById("listOpenSR");
    listOpenSR.innerHTML = "";
    listFiltered.map(async ([id, nama]) => {
      // cari nama apakah sudah ada di listOpenSR
      const isExistOpen = openCloseModel.isExistOpen(id.toString());
      const isExistClose = openCloseModel.isExistClose(id.toString());
      if (isExistOpen) {
        return null;
      }
      if (isExistClose) {
        return null;
      }
      const button = document.createElement("button");
      button.className =
        "my-2 min-w-64 snap-start px-2 py-1 text-center text-white";
      button.innerText = nama;
      // menambah data id pada button
      button.setAttribute("data-id", id);
      button.onclick = onClickOpenSR;
      listOpenSR.appendChild(button);
      return null;
    });
  };
  const onClickOpenSR = (e) => {
    openCloseModel.addOpen(e.target.getAttribute("data-id"));
    createElementOpenSR(e.target.innerText);
  };
  const createElementOpenSR = (nama) => {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.className = "grid grid-cols-10 rounded-xl bg-[#364649] p-2";

    // create span for nama SR
    const spanNamaSR = document.createElement("span");
    spanNamaSR.className =
      "col-span-9 content-center text-white overflow-hidden overflow-ellipsis whitespace-nowrap";
    spanNamaSR.innerText = nama;

    // create button for remove SR
    const buttonRemoveSR = document.createElement("button");
    buttonRemoveSR.className =
      "col-span-1 max-h-6 rounded-xl bg-[#708F96] px-2 font-caviar-bold text-white flex flex-col justify-center items-center";
    buttonRemoveSR.innerText = "X";

    // append span and button to wrapper div
    wrapperDiv.appendChild(spanNamaSR);
    wrapperDiv.appendChild(buttonRemoveSR);

    // get element with id listSelectedOpenSR
    const listSelectedOpenSR = document.getElementById("listSelectedOpenSR");
    listSelectedOpenSR.appendChild(wrapperDiv);

    // add event listener to button remove SR
    buttonRemoveSR.addEventListener("click", () => {
      wrapperDiv.remove();
      openCloseModel.removeOpen(nama);
      setListSelectedOpenSRState(openCloseModel.getOpenSelected());
    });
    resetOpenSR();
  };
  const resetOpenSR = () => {
    document.getElementById("openSRInput").value = "";
    document.getElementById("listOpenSR").innerHTML = "";
  };

  // Close SR
  const onChangeSearchCloseSR = (e) => {
    // menghapus spasi didepan dan akhir
    const keyword = e.target.value.trim().toLowerCase();
    if (keyword === "") {
      // hapus semua child pada element dengan id listCloseSR
      const listCloseSR = document.getElementById("listCloseSR");
      listCloseSR.innerHTML = "";
    } else {
      closeListSearch(keyword);
    }
  };
  const closeListSearch = (keyword) => {
    const filterNamaSR = (keyword) => {
      return listNamaSR.filter(([id, nama]) =>
        nama.toLowerCase().includes(keyword),
      );
    };
    const listFiltered = filterNamaSR(keyword);
    // tambah child pada element dengan id listCloseSR
    const listCloseSR = document.getElementById("listCloseSR");
    listCloseSR.innerHTML = "";
    listFiltered.map(async ([id, nama]) => {
      // cari nama apakah sudah ada di listCloseSR
      const isExistOpen = openCloseModel.isExistOpen(id.toString());
      const isExistClose = openCloseModel.isExistClose(id.toString());
      if (isExistOpen) {
        return null;
      }
      if (isExistClose) {
        return null;
      }
      const button = document.createElement("button");
      button.className =
        "my-2 min-w-64 snap-start px-2 py-1 text-center text-white";
      button.innerText = nama;
      button.setAttribute("data-id", id);
      button.onclick = onClickCloseSR;
      listCloseSR.appendChild(button);
      return null;
    });
  };
  const onClickCloseSR = (e) => {
    openCloseModel.addClose(e.target.getAttribute("data-id"));
    createElementCloseSR(e.target.innerText);
  };
  const createElementCloseSR = (nama) => {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.className = "grid grid-cols-10 rounded-xl bg-[#364649] p-2 m-2";

    // create span for nama SR
    const spanNamaSR = document.createElement("span");
    spanNamaSR.className =
      "col-span-9 content-center text-white overflow-hidden overflow-ellipsis whitespace-nowrap";
    spanNamaSR.innerText = nama;

    // create button for remove SR
    const buttonRemoveSR = document.createElement("button");
    buttonRemoveSR.className =
      "col-span-1 max-h-6 rounded-xl bg-[#708F96] px-2 font-caviar-bold text-white flex flex-col justify-center items-center";
    buttonRemoveSR.innerText = "X";

    // append span and button to wrapper div
    wrapperDiv.appendChild(spanNamaSR);
    wrapperDiv.appendChild(buttonRemoveSR);

    // get element with id listSelectedCloseSR
    const listSelectedCloseSR = document.getElementById("listSelectedCloseSR");
    listSelectedCloseSR.appendChild(wrapperDiv);

    // add event listener to button remove SR
    buttonRemoveSR.addEventListener("click", () => {
      wrapperDiv.remove();
      openCloseModel.removeClose(nama);
      setListSelectedCloseSRState(openCloseModel.getCloseSelected());
    });
    resetCloseSR();
  };
  const resetCloseSR = () => {
    document.getElementById("closeSRInput").value = "";
    document.getElementById("listCloseSR").innerHTML = "";
  };

  const onSubmit = async () => {
    const response = await authenticatedAPI.getDataPermission();
    const dataPermission = (await response.json()).payload;
    if (openCloseModel.getTotalOpenSelected() - 1 === 0) {
      alert("Open SR tidak boleh kosong");
      return;
    }
    let dataSales = null;

    if (dataPermission.permission === 0) {
      if (selectedSA === "") {
        alert("Nama SA tidak boleh kosong");
        return;
      }
      const responseSales = await salesAPI.getSalesByName(selectedSA);
      dataSales = await responseSales.json().then((data) => {
        if (data.length > 0) {
          return data[0];
        } else {
          return null;
        }
      });
    } else {
      const responseSales = await salesAPI.getSalesByUsername(
        dataPermission.username,
      );
      dataSales = await responseSales.json().then((data) => {
        if (data.length > 0) {
          return data[0];
        } else {
          return null;
        }
      });
    }

    const zeroFilterOpen = openCloseModel
      .getOpenSelected()
      .filter((el) => el !== "0");

    const zeroFilterClose = openCloseModel
      .getCloseSelected()
      .filter((el) => el !== "0");

    const responseSalesroom = await salesroomAPI.getSalesroom();
    const data = await responseSalesroom.json();
    const nameSR = [];
    const codeSR = [];
    const opencloseSR = [];

    const lengthOpen = zeroFilterOpen.length;
    const lengthClose = zeroFilterClose.length;

    for (let i = 0; i < lengthOpen; i++) {
      for (let index in data) {
        if (zeroFilterOpen[i] === data[index].id.toString()) {
          nameSR.push(data[index].name);
          codeSR.push(data[index].code);
          opencloseSR.push(1);
          break;
        }
      }
    }
    for (let i = 0; i < lengthClose; i++) {
      for (let index in data) {
        if (zeroFilterClose[i] === data[index].id.toString()) {
          nameSR.push(data[index].name);
          codeSR.push(data[index].code);
          opencloseSR.push(0);
          break;
        }
      }
    }

    let responseSalesroomRequest;
    let result;
    if (nameSR.length > 1) {
      responseSalesroomRequest =
        await salesroomRequestAPI.addSalesroomRequestBulk(
          dataSales.nik_sa,
          dataSales.name,
          nameSR,
          codeSR,
          opencloseSR,
        );
      result = await responseSalesroomRequest.json();
    } else {
      responseSalesroomRequest = await salesroomRequestAPI.addSalesroomRequest(
        dataSales.nik_sa,
        dataSales.name,
        nameSR[0],
        codeSR[0],
        opencloseSR[0],
      );
      result = await responseSalesroomRequest.json();
    }

    if (result.success) {
      setOnPopup(true);
    } else {
      alert("Gagal mengajukan Sales Room");
    }
  };

  useEffect(() => {
    getSalesRoom();
    getDataPermission();
  }, [getSalesRoom, getDataPermission]);

  return (
    <>
      {onPopup && (
        <Popup
          callback={resetOnPopup}
          title={"Request SR"}
          message={"Request berhasil dikirim."}
        />
      )}

      <div className={onPopup ? "blur-md" : ""}>
        <BackToHomeComponent setPage={setPage} />
        <div className="flex min-h-screen w-full flex-col items-center justify-center bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
          {permission === 0 && (
            <SearchSADropBoxComponent
              fetchData={async (listSales) => await setSales(listSales)}
              setSelectedSAParent={(selected) => setSelectedSA(selected)}
            />
          )}
          <SearchTextBoxComponent
            setPage={setPage}
            title="Open SR"
            id_input="openSRInput"
            placeholder_input="Nama Sales Room"
            id_search="listOpenSR"
            id_selected_search="listSelectedOpenSR"
            onChange={onChangeSearchOpenSR}
          />
          <SearchTextBoxComponent
            setPage={setPage}
            title="Close SR"
            id_input="closeSRInput"
            placeholder_input="Nama Sales Room"
            id_search="listCloseSR"
            id_selected_search="listSelectedCloseSR"
            onChange={onChangeSearchCloseSR}
          />

          <button
            id="submit-button"
            className="m-3 mt-2 w-64 rounded-lg bg-[#364649] p-2 text-white"
            onClick={() => onSubmit()}
            disabled={onPopup}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
