import { useState, useMemo, useCallback } from "react";
import SRContainer, { SRContainerManager } from "./srcontainer.js";
import { useEffect } from "react";
import AuthenticatedAPI from "../services/api/authenticated.js";
import SalesroomRequestAPI from "../services/api/salesroomrequest.js";
import SalesAPI from "../services/api/sales.js";
import GoogleAPI from "../services/api/googleapi.js";
import { saveAs } from "file-saver";
import Popup from "../components/popup.js";

export default function SalesSRCheck({ setPage }) {
  const [onRefresh, setOnRefresh] = useState(true);
  const [onPopup, setOnPopup] = useState(false);
  const [onStart, setOnStart] = useState(false);
  const [dataPermission, setDataPermission] = useState(null);
  const [dataHistory, setDataHistory] = useState([]);
  const [onFilter, setOnFilter] = useState(false);
  const googleAPI = useMemo(() => new GoogleAPI(), []);
  const authenticatedAPI = useMemo(() => new AuthenticatedAPI(), []);
  const salesroomRequestAPI = useMemo(() => new SalesroomRequestAPI(), []);
  const salesAPI = useMemo(() => new SalesAPI(), []);
  const srContainerManager = useMemo(() => new SRContainerManager(), []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const searchDeleteElement = useCallback(async () => {
    const data = document.getElementById("listSR");
    const length = data.childNodes.length;
    for (let i = 0; i < length; i++) {
      const childData = data.childNodes[i];
      const isDelete = childData.getAttribute("data-delete");
      if (isDelete === "true") {
        const arrayData = childData.querySelectorAll(
          'span[class="col-span-9 overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-[#364649] px-2 text-white"]',
        );
        const length = arrayData.length;
        for (let i = 0; i < length; i++) {
          // ambil id dari attribute data-id
          const id = arrayData[i].getAttribute("data-id");
          if (id !== null) await salesroomRequestAPI.deleteSalesroomRequest(id);
        }
      }
    }
    setOnRefresh(true);
    setOnPopup(false);
  }, [onPopup]);

  const handleToHome = () => {
    setPage("home");
  };

  const hadleOpenFilter = () => {
    setOnFilter(!onFilter);
  };

  const hadleDownload = async () => {
    const data = document.getElementById("listSR");
    const date = new Date();
    const [month, day, year] = date.toLocaleDateString().split("/");
    const dataCheckedID = [];
    data.childNodes.forEach((element) => {
      // check apakah attribute data-check adalah true
      if (element.getAttribute("data-check") === "true") {
        const arrayData = element.querySelectorAll(
          'span[class="col-span-9 overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-[#364649] px-2 text-white"]',
        );
        const length = arrayData.length;
        for (let i = 0; i < length; i++) {
          // ambil id dari attribute data-id
          const id = arrayData[i].getAttribute("data-id");
          if (id !== null)
            dataCheckedID.push(arrayData[i].getAttribute("data-id"));
        }
      }
    });
    const response = await googleAPI.getSpreadsheet(dataCheckedID);
    if (response.status !== 200) {
      alert("Error : Failed to download file");
      return;
    }
    // Mengambil data dari respons
    const blob = await response.blob();

    // Menyimpan data sebagai file menggunakan file-saver
    saveAs(
      blob,
      `Request Open & Block SR SA BJM ${day.length === 1 ? "0" + day : day}-${month.length === 1 ? "0" + month : month}-${year}.xlsx`,
    );
  };

  const homeButton = () => {
    console.log(onPopup);
    return (
      <button
        onClick={handleToHome}
        className={`absolute m-5 rounded-xl bg-[#708F96] p-2 font-caviar shadow-2xl`}
        key="homeButton0"
        disabled={onPopup}
      >
        Home
      </button>
    );
  };

  const filterButton = () => {
    return (
      <button
        onClick={hadleOpenFilter}
        className={
          "absolute right-0 m-5 rounded-xl bg-[#708F96] p-2 font-caviar shadow-2xl"
        }
        disabled={onPopup}
      >
        Filter
      </button>
    );
  };

  const downloadButton = () => {
    return (
      <button
        onClick={hadleDownload}
        className={
          "absolute right-14 m-5 rounded-xl bg-[#708F96] p-2 font-caviar shadow-2xl"
        }
        disabled={onPopup}
      >
        Download
      </button>
    );
  };

  const getSRContainer = () => {
    if (!onStart) return;
    srContainerManager.init(dataHistory);
    const result = [];
    const lenghtByDate = srContainerManager.sortedByDate.length;
    for (let i = 0; i < lenghtByDate; i++) {
      const lengthOpenItem =
        srContainerManager.sortedByDate[i].options.OPEN.length;
      const lengthCloseItem =
        srContainerManager.sortedByDate[i].options.CLOSE.length;
      const sr_open = [];
      const sr_close = [];
      const id_open = [];
      const id_close = [];
      let timestamp = null;
      let nik_sa = null;
      let name_sa = null;
      for (let j = 0; j < lengthOpenItem; j++) {
        if (timestamp === null) {
          timestamp =
            srContainerManager.sortedByDate[i].options.OPEN[j].timestamp;
          nik_sa = srContainerManager.sortedByDate[i].options.OPEN[j].nik_sa;
          name_sa = srContainerManager.sortedByDate[i].options.OPEN[j].name;
        }
        id_open.push(srContainerManager.sortedByDate[i].options.OPEN[j].id);
        sr_open.push(
          srContainerManager.sortedByDate[i].options.OPEN[j].sr_open_close,
        );
      }
      for (let j = 0; j < lengthCloseItem; j++) {
        id_close.push(srContainerManager.sortedByDate[i].options.CLOSE[j].id);
        sr_close.push(
          srContainerManager.sortedByDate[i].options.CLOSE[j].sr_open_close,
        );
      }

      result.push(
        <SRContainer
          id_open={id_open}
          id_close={id_close}
          sr_open={sr_open}
          sr_closed={sr_close}
          date={timestamp}
          nik_sa={nik_sa}
          name_sa={name_sa}
          keyUnique={`SRContainer${i}`}
          onPopup={onPopup}
          setOnPopUp={setOnPopup}
        />,
      );
    }
    return result;
  };

  const getSRContainerManager = () => {
    if (!onStart) return;
    srContainerManager.init(dataHistory);
    const result = [];
    const lenghtByDate = srContainerManager.sortedByDate.length;
    for (let i = 0; i < lenghtByDate; i++) {
      const lengthOpenItem =
        srContainerManager.sortedByDate[i].options.OPEN.length;
      const lengthCloseItem =
        srContainerManager.sortedByDate[i].options.CLOSE.length;
      const sr_open = [];
      const sr_close = [];
      const id_open = [];
      const id_close = [];
      let timestamp = null;
      let nik_sa = null;
      let name_sa = null;
      for (let j = 0; j < lengthOpenItem; j++) {
        if (timestamp === null) {
          timestamp =
            srContainerManager.sortedByDate[i].options.OPEN[j].timestamp;
          nik_sa = srContainerManager.sortedByDate[i].options.OPEN[j].nik_sa;
          name_sa = srContainerManager.sortedByDate[i].options.OPEN[j].name;
        }
        id_open.push(srContainerManager.sortedByDate[i].options.OPEN[j].id);
        sr_open.push(
          srContainerManager.sortedByDate[i].options.OPEN[j].sr_open_close,
        );
      }
      for (let j = 0; j < lengthCloseItem; j++) {
        id_close.push(srContainerManager.sortedByDate[i].options.CLOSE[j].id);
        sr_close.push(
          srContainerManager.sortedByDate[i].options.CLOSE[j].sr_open_close,
        );
      }

      result.push(
        <SRContainer
          id_open={id_open}
          id_close={id_close}
          sr_open={sr_open}
          sr_closed={sr_close}
          date={timestamp}
          nik_sa={nik_sa}
          name_sa={name_sa}
          keyUnique={`SRContainer${i}`}
          onPopup={onPopup}
          setOnPopUp={setOnPopup}
        />,
      );
    }
    return result;
  };

  const isManager = useMemo(() => {
    if (dataPermission === null) return false;
    if (dataPermission.permission === 0) return true;
    return false;
  }, [dataPermission]);

  useEffect(() => {
    const isAuthenticated = async () => {
      const dataPermissionResponse = await authenticatedAPI.getDataPermission();
      const dataPermissionJson = await dataPermissionResponse.json();
      setDataPermission(dataPermissionJson.payload);

      // Manager
      if (dataPermissionJson.payload.permission === 0) {
        const salesroomResponse =
          await salesroomRequestAPI.getSalesroomRequestToday();
        const salesroomJson = await salesroomResponse.json();
        setDataHistory(salesroomJson);
      }

      // Sales
      else if (dataPermissionJson.payload.permission === 1) {
        const salesResponse = await salesAPI.getSalesByUsername(
          dataPermissionJson.payload.username,
        );
        const salesJson = await salesResponse.json();

        const salesroomResponse =
          await salesroomRequestAPI.getSalesroomRequestByName(
            salesJson[0].name,
          );
        const salesroomJson = await salesroomResponse.json();
        setDataHistory(salesroomJson);
      }
      setOnStart(true);
    };
    if (onRefresh) {
      isAuthenticated();
      setOnRefresh(false);
    }
  }, [authenticatedAPI, salesAPI, salesroomRequestAPI, onRefresh]);

  if (isManager)
    return (
      <>
        {onPopup &&
          (() => {
            scrollToTop(); // Panggil fungsi scrollToTop
            return (
              <Popup
                title="Delete request SR?"
                message="Are you sure?"
                callback={() => searchDeleteElement()}
                button={true}
                callbackButton={() => setOnPopup(false)}
              />
            );
          })()}
        <div className={onFilter ? "block" : "hidden"}>
          <div className="absolute z-10 flex max-h-screen min-h-screen min-w-full flex-col items-center justify-center bg-transparent">
            <div className="flex h-96 w-96 flex-col items-center justify-center rounded-3xl bg-gradient-to-b from-[#708F96] to-[#364649] text-center font-caviar">
              <div className="m-auto">
                <span className="m-5 font-caviar-bold">Filter</span>
                <div className="grid grid-cols-2 grid-rows-2">
                  <label className="m-2">From</label>
                  <input
                    type="date"
                    className="m-2 rounded-xl bg-[#708F96] p-2 text-white"
                  />
                  <label className="m-2">To</label>
                  <input
                    type="date"
                    className="m-2 rounded-xl bg-[#708F96] p-2 text-white"
                  />
                </div>
              </div>
              <div className="m-auto">
                <span className="m-5 font-caviar-bold">Sort</span>
                <div className="flex gap-10">
                  <button className="rounded-xl bg-[#708F96] p-2 text-white">
                    A - Z
                  </button>
                  <button className="rounded-xl bg-[#708F96] p-2 text-white">
                    By Date
                  </button>
                </div>
              </div>
              <div className="m-5 flex gap-10">
                <button className="rounded-xl bg-[#708F96] p-2 px-10 text-white">
                  Apply
                </button>
                <button
                  className="rounded-xl bg-[#708F96] p-2 px-10 text-white"
                  onClick={() => setOnFilter(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={onFilter || onPopup ? "blur-sm" : ""}>
          {onStart && homeButton(!onFilter)}
          {onStart && filterButton()}
          {onStart && downloadButton()}
          <div className="flex min-h-screen w-full flex-col items-center gap-6 bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
            <div className="mt-20 gap-10">
              <h1
                className="text-center font-caviar-bold text-3xl text-white"
                key={`title`}
              >
                Open / Block SR
              </h1>
              <div id="listSR" key={`containerSR`}>
                {getSRContainerManager()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else {
    return (
      <>
        {onPopup &&
          (() => {
            scrollToTop(); // Panggil fungsi scrollToTop
            return (
              <Popup
                title="Delete request SR?"
                message="Are you sure?"
                callback={() => searchDeleteElement()}
                button={true}
                callbackButton={() => setOnPopup(false)}
              />
            );
          })()}
        <div className={onPopup ? "cursor-not-allowed blur-sm" : "block"}>
          {onStart && homeButton(!onFilter)}
          {onStart && filterButton(!onFilter)}
          <div className="flex min-h-screen w-full flex-col items-center gap-6 bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
            <div className="mt-20 gap-10">
              <h1
                className="text-center font-caviar-bold text-3xl text-white"
                key={`title`}
              >
                Open / Block SR
              </h1>
              <div id="listSR" key={`containerSR`}>
                {getSRContainer()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
