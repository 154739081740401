import fetchService from "../fetch.js";
import API from "./main_api.js";

export default class SalesroomAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api/salesroom";
  }
  async getSalesroom() {
    const response = await fetchService(this.url, "GET");
    return response;
  }
  async getSalesroomByName(name) {
    const response = await fetchService(`${this.url}/${name}`, "GET");
    return response;
  }
  async addSalesroom(code, name) {
    const response = await fetchService(this.url, "POST", {
      code: code,
      name: name,
    });
    return response;
  }
  async updateSalesroom(
    code,
    name,
    new_code = undefined,
    new_name = undefined,
  ) {
    let data = {};
    const _code = code !== undefined;
    const _name = name !== undefined;
    const _new_code = new_code !== undefined;
    const _new_name = new_name !== undefined;

    if (!_code && !_name) {
      return null;
    } else {
      data.code = code;
      data.name = name;
    }
    if (_new_code) data.new_code = new_code;
    if (_new_name) data.new_name = new_name;

    const response = await fetchService(`${this.url}/${code}`, "PUT", data);
    return response;
  }
  async deleteSalesroom(code, name) {
    const response = await fetchService(`${this.url}/${code}`, "DELETE", {
      code: code,
      name: name,
    });
    return response;
  }
}
