import fetchService from "../fetch";
import CryptoJS from "crypto-js";
import API from "./main_api.js";
import { getBackendURL } from "../../utilities.js";

export default class AuthenticationAPI extends API {
  async login(username, password) {
    const hashedPassword = CryptoJS.SHA256(password).toString().toLowerCase();
    const response = await fetchService(getBackendURL() + "/login", "POST", {
      username: username,
      password: hashedPassword,
    });
    return response;
  }
  async logout() {
    const response = await fetchService(getBackendURL() + "/logout", "GET");
    return response;
  }
}
