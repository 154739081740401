export default class OpenBlock {
  constructor(openSelected, closeSelected) {
    this.openSelected = [];
    this.closeSelected = [];
    for (let item in openSelected) {
      this.openSelected.push(item);
    }
    for (let item in closeSelected) {
      this.closeSelected.push(item);
    }
  }
  addOpen(item) {
    this.openSelected.push(item);
  }
  addClose(item) {
    this.closeSelected.push(item);
  }
  removeOpen(item) {
    this.openSelected = this.openSelected.filter((i) => i !== item);
  }
  removeClose(item) {
    this.closeSelected = this.closeSelected.filter((i) => i !== item);
  }
  isExistOpen(item) {
    return this.openSelected.includes(item);
  }
  isExistClose(item) {
    return this.closeSelected.includes(item);
  }
  getOpenSelected() {
    return this.openSelected;
  }
  getCloseSelected() {
    return this.closeSelected;
  }
  getTotalOpenSelected() {
    return this.openSelected.length;
  }
  getTotalCloseSelected() {
    return this.closeSelected.length;
  }
  getOpenBlock() {
    return {
      openSelected: this.openSelected,
      closeSelected: this.closeSelected,
    };
  }
}
