import fetchService from "../fetch.js";
import API from "./main_api.js";

export default class GoogleAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api/get-spreadsheet";
  }
  async getSpreadsheet(id) {
    return await fetchService(this.url, "POST", { id: id });
  }
}
