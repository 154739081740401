import { useState } from "react";
import AuthenticationAPI from "../services/api/authentication.js";

export default function Login({ setPage }) {
  const [usernameOnCorrection, setUsernameOnCorrection] = useState(false);
  const [passwordOnCorrection, setPasswordOnCorrection] = useState(false);
  const authenticationAPI = new AuthenticationAPI();
  const inputOnCorection = "w-64 p-2 border-2 rounded-lg";
  const inputOnCorectionError =
    "w-64 p-2 bg-red-500 border-2 border-red-500 rounded-lg placeholder:text-white";

  const getUsername = () => {
    return document.getElementById("username").value;
  };

  const getPassword = () => {
    return document.getElementById("password").value;
  };

  const resetUsername = () => {
    document.getElementById("username").value = "";
  };

  const resetPassword = () => {
    document.getElementById("password").value = "";
  };

  const resetForm = () => {
    resetUsername();
    resetPassword();
    document.getElementById("username").focus();
  };

  const handleLogin = async () => {
    const username = getUsername();
    const password = getPassword();
    if (username === "" || password === "") {
      alert("Please fill in all fields");
      return;
    }

    const response = await authenticationAPI.login(username, password);
    if (response === null) {
      setUsernameOnCorrection(true);
      setPasswordOnCorrection(true);
      return;
    } else {
      if (response.status === 200) {
        setPage("home");
      } else {
        setUsernameOnCorrection(true);
        setPasswordOnCorrection(true);
      }
    }
    resetForm();
  };

  return (
    <div
      className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-[#364649] to-[#708F96] 
      font-caviar"
    >
      <input className="hidden w-64 rounded-lg border-2 border-red-500 bg-red-500 p-2 placeholder:text-white" />
      <div className="relative flex min-h-64 w-auto flex-col items-center rounded-[3rem] bg-[#708F96] p-5 shadow-2xl">
        <h1 className="font-caviar-bold text-3xl">Log In</h1>
        <div className="m-3">
          <h1 className="font-caviar-bold">Username:</h1>
          <input
            id="username"
            className={
              usernameOnCorrection ? inputOnCorectionError : inputOnCorection
            }
            type="text"
            placeholder="Enter your username"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                document.getElementById("password").focus();
                setPasswordOnCorrection(false);
              }
            }}
            onChange={() => setUsernameOnCorrection(false)}
            onClick={() => setUsernameOnCorrection(false)}
          />
        </div>
        <div className="m-3">
          <h1 className="font-caviar-bold">Password:</h1>
          <input
            id="password"
            className={
              passwordOnCorrection ? inputOnCorectionError : inputOnCorection
            }
            type="password"
            placeholder="Enter your password"
            // saat tekan enter, panggil fungsi handleLogin
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
            onChange={() => setPasswordOnCorrection(false)}
            onClick={() => setPasswordOnCorrection(false)}
          />
        </div>
        <button
          id="login-button"
          className="m-3 mt-2 w-64 rounded-lg bg-[#364649] p-2 text-white"
          onClick={() => handleLogin()}
        >
          Log In
        </button>
      </div>
    </div>
  );
}
