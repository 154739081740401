import fetchService from "../fetch";
import API from "./main_api.js";

export default class AccountAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api/account";
  }
  async getAccount() {
    const response = await fetchService(this.url, "GET");
    return response;
  }
  async addAccount(username, password) {
    const response = await fetchService(this.url, "POST", {
      password: password,
      username: username,
    });
    return response;
  }
  async updateAccount(
    password,
    username,
    newPassword = undefined,
    newPermission = undefined,
  ) {
    let data = {};
    const _password = password !== undefined;
    const _username = username !== undefined;
    const _newPassword = newPassword !== undefined;
    const _newPermission = newPermission !== undefined;

    if (!_password && !_username) {
      return null;
    } else {
      data.password = password;
      data.username = username;
    }
    if (_newPassword) data.newPassword = newPassword;
    if (_newPermission) data.newPermission = newPermission;

    const response = await fetchService(`${this.url}`, "PUT", data);
    return response;
  }
  async deleteAccount(username) {
    const response = await fetchService(`${this.url}`, "DELETE", {
      username: username,
    });
    return response;
  }
}
