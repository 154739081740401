import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import AuthenticatedAPI from "../services/api/authenticated";
import SalesAPI from "../services/api/sales";
import Popup from "../components/popup";

export default function SRContainer({
  id_open,
  id_close,
  sr_open,
  sr_closed,
  date,
  name_sa,
  keyUnique,
  onPopUp,
  setOnPopUp,
}) {
  const [onStart, setOnStart] = useState(false);
  const [spv, setSpv] = useState(false);
  const [SR_Open, setSR_Open] = useState([]);
  const [SR_Closed, setSR_Closed] = useState([]);
  const [dataPermission, setDataPermission] = useState(null);
  const [check, setCheck] = useState(false);
  const authenticatedAPI = useMemo(() => new AuthenticatedAPI(), []);
  const salesAPI = useMemo(() => new SalesAPI(), []);
  const onPopUpHandler = useMemo(() => onPopUp, [onPopUp]);
  const [onDelete, setOnDelete] = useState(false);

  useEffect(() => {
    const getAuthenticated = async () => {
      const data = await authenticatedAPI.getDataPermission();
      const dataJson = (await data.json()).payload;
      setDataPermission(dataJson);
      if (dataJson.permission === 0) {
        setSpv(true);
      }
      if (dataJson.permission === 1) {
        setSpv(false);
      }
      setSR_Open(sr_open);
      setSR_Closed(sr_closed);
      setOnStart(true);
    };
    getAuthenticated();
  }, [authenticatedAPI, salesAPI, sr_open, sr_closed]);

  const getNameORDate = () => {
    if (spv) {
      return (
        <>
          <div className="flex justify-between gap-10">
            <h1 className="font-caviar-bold text-2xl text-[#364649]">
              {name_sa}
            </h1>
            <div className="flex items-center justify-end gap-2">
              <button
                className="rounded-lg bg-red-500 px-2 text-white"
                onClick={() => {
                  setOnDelete(true);
                  setOnPopUp(true);
                  return;
                }}
                disabled={onPopUpHandler}
              >
                Delete
              </button>
              <button
                onClick={() => setCheck(!check)}
                disabled={onPopUpHandler}
              >
                {check && <img src="./img/check.png" alt="check" />}
                {!check && <img src="./img/uncheck.png" alt="uncheck" />}
              </button>
            </div>
          </div>
          <h1 className="font-caviar-bold text-xl text-[#364649]">
            {parseDate(date)}
          </h1>
        </>
      );
    } else {
      return (
        <>
          <div className="flex items-center justify-between gap-5">
            <h1 className="my-2 text-center font-caviar-bold text-lg text-[#364649] underline underline-offset-8">
              {parseDate(date)}
            </h1>
            <button
              className="h-8 rounded-lg bg-red-500 px-2 py-1 text-center text-[16px] text-white"
              onClick={() => {
                setOnDelete(true);
                setOnPopUp(true);
                return;
              }}
              disabled={onPopUpHandler}
            >
              Delete
            </button>
          </div>
        </>
      );
    }
  };
  const setID = (id, sr) => {
    if (sr === "None") id = undefined;
    return (
      <>
        <span
          data-id={id}
          className="col-span-9 overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-[#364649] px-2 text-white"
        >
          - {sr}
        </span>
      </>
    );
  };
  const getClose = useMemo(() => {
    const result = [];
    let counter = 0;
    SR_Closed.map((sr) => {
      result.push(
        <div className="grid grid-cols-10 gap-3" key={`close-${counter}`}>
          <div className="col-span-9 grid grid-cols-10">
            <span className="col-span-1"></span>
            {setID(id_close[counter], sr)}
          </div>
        </div>,
      );
      counter++;
      return null;
    });
    if (result.length === 0) {
      return (
        <div className="grid grid-cols-10 gap-3">
          <div className="col-span-9 grid grid-cols-10">
            <span className="col-span-1"></span>
            <span
              data-id={id_close[counter]}
              className="col-span-9 overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full bg-[#364649] px-2 text-white"
            >
              - None
            </span>
          </div>
        </div>
      );
    } else {
      return result;
    }
  }, [SR_Closed, id_close]);
  const getOpen = useMemo(() => {
    const result = [];
    let counter = 0;
    SR_Open.map((sr) => {
      result.push(
        <div className="grid grid-cols-10 gap-3" key={`close-${counter}`}>
          <div className="col-span-9 grid grid-cols-10">
            <span className="col-span-1"></span>
            {setID(id_open[counter], sr)}
          </div>
        </div>,
      );
      counter++;
      return null;
    });
    return result;
  }, [SR_Open, id_open]);
  const parseDate = (date) => {
    // convert to long date
    // "2024-05-05T08:02:58.000Z"
    // merubah date menjadi format yang lebih mudah dibaca
    const date_ = date.split("T")[0];
    const time_ = date.split("T")[1].split(".")[0];
    let dateArray = date_.split("-");
    const day = parseInt(dateArray[2]);
    const month = parseInt(dateArray[1]);
    const year = parseInt(dateArray[0]);
    const dateObj = new Date(year, month - 1, day);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const complate_date = dateObj.toLocaleDateString("en-US", options);
    // convert to Indonesian Language
    const dayConvert = {
      Sunday: "Minggu",
      Monday: "Senin",
      Tuesday: "Selasa",
      Wednesday: "Rabu",
      Thursday: "Kamis",
      Friday: "Jumat",
      Saturday: "Sabtu",
    };
    const monthConvert = {
      January: "Januari",
      February: "Februari",
      March: "Maret",
      April: "April",
      May: "Mei",
      June: "Juni",
      July: "Juli",
      August: "Agustus",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "Desember",
    };
    // replace all "," and split by ""
    dateArray = complate_date.replace(/,/g, "").split(" ");
    const dayInd = dayConvert[dateArray[0]];
    const monthInd = monthConvert[dateArray[1]];
    return `${dayInd}, ${dateArray[2]} ${monthInd} ${dateArray[3]} ${time_}`;
  };

  return (
    <div
      className="m-2 min-w-64 max-w-screen-sm rounded-2xl bg-[#708F96] px-5 py-3 shadow-2xl"
      key={keyUnique}
      data-check={check}
      data-delete={onDelete}
    >
      {onStart && getNameORDate(parseDate)}
      <div className="flex flex-col gap-2">
        <span>Open:</span>
        {onStart && getOpen}
      </div>
      <div className="flex flex-col gap-2">
        <span>Close:</span>
        {onStart && getClose}
      </div>
    </div>
  );
}

export class SRContainerManager {
  init(data) {
    this.data = data;
    this.sortedByDate = [];
    this.sortByDate();
  }
  sortByDate() {
    const sortedData = [];
    // sortir data berdasarkan timestamp 1 minggu terakhir
    const week = 1000 * 60 * 60 * 24 * 7;
    const now = Date.now();
    const lastWeek = now - week;
    const thisWeek = this.data.filter(
      (el) => new Date(el.timestamp) > lastWeek,
    );
    // sortir data berdasarkan timestamp dari terbaru ke terlama
    this.data = thisWeek.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    );

    // Menggunakan reduce untuk mengelompokkan data berdasarkan timestamp unik
    const groupedData = this.data.reduce((acc, cur) => {
      if (!acc[cur.timestamp]) {
        acc[cur.timestamp] = {
          timestamp: cur.timestamp,
          options: { OPEN: [], CLOSE: [] },
        };
      }

      // Memasukkan data ke dalam opsi OPEN atau CLOSE berdasarkan nilai open_or_close
      if (cur.open_or_close === 1) {
        acc[cur.timestamp].options.OPEN.push(cur);
      } else if (cur.open_or_close === 0) {
        acc[cur.timestamp].options.CLOSE.push(cur);
      }

      return acc;
    }, {});

    // Mengubah hasil pengelompokkan menjadi array
    for (const timestamp in groupedData) {
      sortedData.push(groupedData[timestamp]);
    }
    this.sortedByDate = sortedData;
  }
  getSortedData() {
    if (this.sortedByDate.length === 0) this.sortByDate();
    return this.sortedByDate;
  }
}
