import { useEffect, useState, useMemo } from "react";
import AuthenticatedAPI from "../services/api/authenticated";
import AccountAPI from "../services/api/account";
import CryptoJS from "crypto-js";

export default function Setting({ setPage }) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [onStart, setOnStart] = useState(false);

  const authenticatedAPI = useMemo(() => new AuthenticatedAPI(), []);
  const accountAPI = useMemo(() => new AccountAPI(), []);

  const handleToHome = () => {
    setPage("home");
  };
  const handleIsNewPasswordValid = () => {
    for (let i = 0; i < confirmNewPassword.length; i++) {
      if (newPassword[i] !== confirmNewPassword[i]) {
        document.getElementById("confirm_new_password").style.backgroundColor =
          "#FF0000";
        return;
      } else {
        document.getElementById("confirm_new_password").style.backgroundColor =
          "#708F96";
      }
    }
  };

  const handleChangePassword = async () => {
    const dataPermission = await authenticatedAPI.getDataPermission();
    if (dataPermission === null) {
      setPage("login");
    }
    if (dataPermission.status !== 200) {
      setPage("login");
    }
    const dataPermissionPayload = (await dataPermission.json()).payload;
    const response = await accountAPI.updateAccount(
      CryptoJS.SHA256(password).toString(),
      dataPermissionPayload.username,
      CryptoJS.SHA256(newPassword).toString(),
      dataPermissionPayload.permission,
    );
    if (response === null) {
      console.log("Failed to change password");
    }
    const responseData = await response.json();
    if (!responseData.success) {
      console.log("Failed to change password");
    } else {
      alert("Password changed successfully");
      setPage("home");
    }
  };

  useEffect(() => {
    const isAuthenticated = async () => {
      const response0 = await authenticatedAPI.isAuthenticated();
      if (response0 === null) {
        setPage("login");
      }
      if (response0.status !== 200) {
        setPage("login");
      }

      setOnStart(true);
    };
    if (!onStart) isAuthenticated();
    else {
      if (confirmNewPassword.length !== 0) handleIsNewPasswordValid();
    }
  }, [
    authenticatedAPI,
    setPage,
    newPassword,
    confirmNewPassword,
    handleIsNewPasswordValid,
  ]);

  if (!onStart) return <></>;
  return (
    <>
      <button
        className="absolute m-5 rounded-xl bg-[#708F96] p-2 text-end font-caviar shadow-2xl"
        onClick={() => handleToHome("home")}
      >
        Home
      </button>

      <div className="min-h-screen bg-gradient-to-b from-[#364649] to-[#708F96] font-caviar">
        <div className="flex max-h-screen min-h-screen grid-rows-10 flex-col justify-center">
          <div className="m-10 flex items-end justify-center text-5xl sm:m-40">
            <span className="font-bold">Setting</span>
          </div>
          <div className="flex w-full flex-col items-center justify-start">
            <label className="m-2 text-2xl">Password</label>
            <input
              className="m-2 rounded-xl bg-[#708F96] p-2 text-center"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onFocus={() => setPassword("")}
            />
            <label className="m-2 text-2xl">New Password</label>
            <input
              className="m-2 rounded-xl bg-[#708F96] p-2 text-center"
              type="password"
              value={newPassword}
              id="new_password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              onFocus={() => setNewPassword("")}
            />
            <label className="m-2 text-2xl">Confirm New Password</label>
            <input
              className="m-2 rounded-xl bg-[#708F96] p-2 text-center"
              type="password"
              value={confirmNewPassword}
              id="confirm_new_password"
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
                if (newPassword !== e.target.value) {
                  handleIsNewPasswordValid();
                }
              }}
              onFocus={() => setConfirmNewPassword("")}
            />
            <button
              className="m-2 flex items-start justify-center rounded-xl bg-[#364649] p-2 text-center text-white"
              onClick={() => handleChangePassword()}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
