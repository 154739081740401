import fetchService from "../fetch";
import API from "./main_api.js";

export default class AuthenticatedAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api";
  }
  async isAuthenticated() {
    return await fetchService(this.url, "GET");
  }
  async getDataPermission() {
    return await fetchService(this.url + "/permission", "GET");
  }
}
