import fetchService from "../fetch";
import API from "./main_api.js";

export default class SalesAPI extends API {
  constructor() {
    super();
    this.url = this.mainUrl + "/api/sales";
  }
  async getSales() {
    const response = await fetchService(this.url, "GET");
    return response;
  }
  async getSalesByNameLike(name) {
    const response = await fetchService(`${this.url}/name_like/${name}`, "GET");
    return response;
  }
  async getSalesByName(name) {
    const response = await fetchService(`${this.url}/name/${name}`, "GET");
    return response;
  }
  async getSalesByUsername(username) {
    const response = await fetchService(
      `${this.url}/username/${username}`,
      "GET",
    );
    return response;
  }
  async addSales(nik_sa, name) {
    const response = await fetchService(this.url, "POST", {
      nik_sa: nik_sa,
      name: name,
    });
    return response;
  }
  async updateSales(
    nik_sa,
    name,
    new_nik_sa = undefined,
    new_name = undefined,
  ) {
    let data = {};
    const _nik_sa = nik_sa !== undefined;
    const _name = name !== undefined;
    const _new_nik_sa = new_nik_sa !== undefined;
    const _new_name = new_name !== undefined;

    if (!_nik_sa && !_name) {
      return null;
    } else {
      data.nik_sa = nik_sa;
      data.name = name;
    }
    if (_new_nik_sa) data.new_nik_sa = new_nik_sa;
    if (_new_name) data.new_name = new_name;

    const response = await fetchService(`${this.url}/${nik_sa}`, "PUT", data);
    return response;
  }
  async deleteSales(nik_sa, name) {
    const response = await fetchService(`${this.url}/${nik_sa}`, "DELETE", {
      nik_sa: nik_sa,
      name: name,
    });
    return response;
  }
}
